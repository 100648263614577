import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStateValue } from '../../../state'
import BurgerMenuBtn from './BurgerMenuBtn/BurgerMenuBtn'
import Sidedrawer from './Sidedrawer/Sidedrawer'
import Backdrop from '../../UI/Backdrop/Backdrop'
import NavItems from './NavItems/NavItems'
import NavLink from './NavItems/NavLink/NavLink'
import { StyledBurgerMenu } from './BurgerMenu.styled'

const BurgerMenu = ({ open, toggleMenu, closeMenu }) => {
  const props = useStateValue()
  const { t, i18n } = useTranslation()

  const octopodNavItems = [
    {
      id: '1',
      title: 'business',
      authorization: true,
      type: 'header',
      subitems: [
        {
          id: '1.1',
          title: 'projects',
          authorization: true,
          type: 'accordion',
          subitems: [
            {
              id: '1.1.1',
              title: 'projectsList',
              authorization: true,
              type: 'navLinkMedium',
              url: `/projects/billable?locale=${i18n.language}`,
              subitems: [],
            },
            {
              id: '1.1.2',
              title: 'projectGroups',
              authorization: true,
              type: 'navLinkMedium',
              url: `/project_groups?locale=${i18n.language}`,
              subitems: [],
            },
          ],
        },
        {
          id: '1.2',
          title: 'clients',
          authorization: true,
          type: 'accordion',
          subitems: [
            {
              id: '1.2.1',
              title: 'sectorList',
              authorization: true,
              type: 'navLinkMedium',
              url: `/sectors?locale=${i18n.language}`,
              subitems: [],
            },
            {
              id: '1.2.2',
              title: 'clients',
              authorization: true,
              type: 'navLinkMedium',
              url: `/customers?locale=${i18n.language}`,
              subitems: [],
            },
            {
              id: '1.2.3',
              title: 'clientsGroups',
              authorization: true,
              type: 'navLinkMedium',
              url: `/customer_groups?locale=${i18n.language}`,
              subitems: [],
            },
          ],
        },
        {
          id: '1.3',
          title: 'staffing',
          authorization: true,
          type: 'navLink',
          url: props.paths.weeklyActivityPath,
          subitems: [],
        },
        {
          id: '1.4',
          title: 'projectsWithTurnoverToBreakdown',
          authorization: true,
          type: 'navLink',
          url: `/projects/billable/with_turnover_to_breakdown?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '1.5',
          title: 'expensesPerProject',
          authorization: true,
          type: 'navLink',
          url: `/projects/expenses_reports?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '1.6',
          title: 'activityPerTeam',
          authorization: true,
          type: 'navLink',
          url: `/activity_overview?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '1.7',
          title: 'subcontractorsActivityOverview',
          authorization: true,
          type: 'navLink',
          url: `/project_expenses?locale=${i18n.language}`,
          subitems: [],
        },
      ],
    },
    {
      id: '2',
      title: 'internal',
      authorization: true,
      type: 'header',
      subitems: [
        {
          id: '2.1',
          title: 'internalProjects',
          authorization: true,
          type: 'navLink',
          url: `/projects/internal?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '2.2',
          title: 'myTeam',
          authorization: true,
          type: 'accordion',
          subitems: [
            {
              id: '2.2.1',
              title: 'projectsAndLeadPerTeam',
              authorization: true,
              type: 'navLinkMedium',
              url: `/projects_involvements?locale=${i18n.language}`,
              subitems: [],
            },
            {
              id: '2.2.2',
              title: 'happinessIndicatorPerTeam',
              authorization: true,
              type: 'navLinkMedium',
              url: `/happiness_reports?locale=${i18n.language}`,
              subitems: [],
            },
          ],
        },
        {
          id: '2.3',
          title: 'arrivalsDepartures',
          authorization: true,
          type: 'navLink',
          url: `/people?locale=${i18n.language}`,
          subitems: [],
        },
      ],
    },
    {
      id: '3',
      title: 'finance',
      authorization: true,
      type: 'header',
      subitems: [
        {
          id: '3.1',
          title: 'periodsCheck',
          authorization: true,
          type: 'accordion',
          subitems: [
            {
              id: '3.1.1',
              title: 'managePeriods.title',
              authorization: true,
              type: 'navLinkMedium',
              url: `/periods/manage?locale=${i18n.language}`,
              subitems: [],
            },
            {
              id: '3.1.2',
              title: 'crossSubsidiariesProjectInputs',
              authorization: true,
              type: 'navLinkMedium',
              url: `/projects/cross_subsidiaries_projects?locale=${i18n.language}`,
              subitems: [],
            },
            {
              id: '3.1.3',
              title: 'permanentActivitiesInputs',
              authorization: props.personIsAdmin,
              type: 'navLinkMedium',
              url: `/activities/permanent?locale=${i18n.language}`,
              subitems: [],
            },
          ],
        },
        {
          id: '3.2',
          title: 'mealExpensesCheck',
          authorization: props.personMealExpensesEnabled,
          type: 'navLink',
          url: `/meal_refunds/index?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '3.3',
          title: 'vacationCheck',
          authorization: props.personIsAdmin,
          type: 'navLink',
          url: `/vacations/index?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '3.4',
          title: 'exports',
          authorization: true,
          type: 'navLink',
          url: `/exports?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '3.5',
          title: 'synchronizationNav',
          authorization: props.personIsAdmin,
          type: 'navLink',
          url: `/synchronization?locale=${i18n.language}`,
          subitems: [],
        },
      ],
    },
    {
      id: '4',
      title: 'hr',
      authorization: true,
      type: 'header',
      subitems: [
        {
          id: '4.1.1',
          title: 'directory',
          authorization: true,
          type: 'navLink',
          url: `/people?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '4.1.2',
          title: 'editHistory',
          authorization: true,
          type: 'navLink',
          url: `/changes?locale=${i18n.language}`,
          subitems: [],
        },
        {
          id: '4.2',
          title: 'Octolidays',
          authorization: props.personIsHRAdmin,
          type: 'accordion',
          subitems: [
            {
              id: '4.2.1',
              title: 'consecutivePaidLeaves',
              authorization: props.personIsHRAdmin,
              type: 'navLinkMedium',
              url: `/people/consecutive_paid_leaves?locale=${i18n.language}`,
              subitems: [],
            },
          ],
        },
      ],
    },
  ]

  return (
    <StyledBurgerMenu id="burger-menu">
      <BurgerMenuBtn
        id="burger-btn"
        open={open}
        setOpen={toggleMenu}
      />
      <Sidedrawer open={open}>
        {
          octopodNavItems.map(
            (navItem) => navItem.authorization && <NavItems key={navItem.id} navItem={navItem} />,
          )
        }
        <div className="bottomLinks">
          {
            props.personIsAdmin
            && (
            <div style={{ marginLeft: '20px' }}>
              <NavLink link={props.paths.adminPath} title={t('admin')} type="bottomLink" />
            </div>
            )
          }
          {
            <div style={{ marginLeft: '20px' }}>
              <NavLink link={props.paths.signOutPath} title={t('signout')} type="bottomLink" />
            </div>
          }
        </div>
      </Sidedrawer>
      <Backdrop
        open={open}
        close={closeMenu}
        top="belowNavbar"
      />
    </StyledBurgerMenu>
  )
}

export default BurgerMenu
